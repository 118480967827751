<template>
  <div>
    <rxNavBar
      v-if="androidFlg == false"
      title="新人试岗申请"
      androidOrIOSFlag="true"
    ></rxNavBar>
    <rxNavBar v-else title="新人试岗申请"></rxNavBar>
    <!--        搜索框/状态-->
    <div class="head">
      <div class="searchDiv">
        <div class="search">
          <input
            placeholder="姓名/手机号/证件号"
            v-model="searchValue"
            @keyup.enter="search"
          />
        </div>
      </div>
      <!--            下拉-->
      <div class="downMenu" @click="showPicker = true">
        <div class="downMenuValue">
          {{ columns[number] ? columns[number].dictionaryTitle : "待我审" }}
        </div>
        <div :class="showPicker ? 'uparrows' : 'arrows'"></div>
      </div>
      <van-popup v-model="showPicker" position="bottom">
        <van-picker
          show-toolbar
          :columns="columns"
          :default-index="number"
          value-key="dictionaryTitle"
          @cancel="showPicker = false"
          @confirm="onConfirm"
        />
      </van-popup>
    </div>
    <div class="blank-weight"></div>
    <div class="reviews">
      <van-pull-refresh
        v-model="refreshing"
        @refresh="onRefresh"
        v-if="orders.length != 0"
      >
        <van-list
          v-model="loading"
          :finished="finished"
          :finished-text="noContent ? '' : '已经到底了'"
          @load="onLoad"
        >
          <div class="review" v-for="(item, index) in orders" :key="index">
            <div :class="'top-title top-title-' + item.approvalStatus">
              {{ item.approvalStatusStr }}
            </div>
            <div class="vacationForm">
              <div class="vacationType">
                <span>{{ "姓 名:" + item.name }}</span>
              </div>

              <!-- <div><span class="text">姓 名:</span><span class="content">{{ item.operDate }}</span></div> -->
              <div>
                <span class="text">职 务:</span
                ><span class="content">{{ item.dutyName }}</span>
              </div>
              <div>
                <span class="text">部 门:</span
                ><span class="content">{{ item.departmentName }}</span>
              </div>
              <div>
                <span class="text">上 级:</span
                ><span class="content">{{ item.seniorStaffName }}</span>
              </div>
            </div>
            <div class="line"></div>

            <div class="bottom">
              <div
                class="check"
                v-if="checkAuthList(authButtonsList, 'approval') && (item.seniorStaff_id == staffId) && (item.approvalStatus == '1')"
                @click="saveDepartApply('approval', item)"
              >
                审批
              </div>
              <!-- <div class="check"
              v-if="checkAuthList(authButtonsList, 'cancel') && (item.approvalStatus == '1')"
              @click="revoke(item)">
                撤销
                </div> -->
              <div
                class="look"
                @click="detail(item)"
              >
                详情
              </div>
            </div>
          </div>
        </van-list>
      </van-pull-refresh>
      <common-empty v-if="isEmptuyFlag"></common-empty>
      <div style="height: 70px"></div>
      <!-- <div class="sub_btn">
      <van-button  class="saveButton_Enable" size="large" v-if="checkAuthList(authButtonsList,'add')"
       @click="saveDepartApply('add')">添加
            </van-button>
      </div> -->
    </div>
    <van-popup
      class="van-popup--bottom"
      v-model="acceptedShow"
      position="bottom"
      :style="{ height: '260px' }"
    >
      <div class="popMaincontant">
        <div class="part-inputpart-row">
          <span class="otherExpenses-row-enablenon part-inputpart-row-header"
            >撤销原因</span
          >
          <!-- <span class="smallgraytext">(非必填)</span> -->
        </div>
        <van-field
          v-model="approvalOpinion"
          autosize
          type="textarea"
          class="mes_body"
          rows="3"
        />

      <div class="sub_btn">
            <van-button  :class=" approvalOpinion == '' ? 'saveButton_Disable' : 'saveButton_Enable'" :disabled="approvalOpinion == ''" size="large" @click="revokeSave()">保存
            </van-button>
          </div>

      </div>
    </van-popup>
  </div>
</template>

<script>
import {
  checkAndroid,
  checkAuthList,
  checkIOS,
  getStaffId,
  responseUtil,
} from "../../../libs/rongxunUtil";
import {
  getStaffFuntionModelList,
  mainTrialPlanRecordPage,
  queryBaseData,
  browseEmploymentApprovalList,
  resumeApprovalRevoke,
  browseInterpolateApprovalList,
} from "../../../getData/getData";
import {
  Button,
  Dialog,
  Divider,
  DropdownItem,
  DropdownMenu,
  List,
  NavBar,
  Picker,
  Popup,
  PullRefresh,
  Search,
  Field,
} from "vant";
import rxNavBar from "../../../components/rongxun/rx-navBar/navBar.vue";

export default {
  name: "staffEntry",
  components: {
    [Button.name]: Button,
    [Search.name]: Search,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
    [Divider.name]: Divider,
    [NavBar.name]: NavBar,
    [List.name]: List,
    rxNavBar,
    [Picker.name]: Picker,
    [Popup.name]: Popup,
    [PullRefresh.name]: PullRefresh,
    [Field.name]: Field,
  },
  data() {
    return {
      androidFlg: "",
      authButtonsList: [],
      refreshing: false,
      loading: true,
      finished: false,
      noContent: false,
      isEmptuyFlag: false,
      page: { currentPage: 1, numberPage: 10 },
      orders: [],
      count: 0,
      //搜索/状态
      searchValue: "",
      showPicker: false,
      columns: [
        {
          dictionaryTitle: "全部",
          dictionaryValue: "0",
        },
        {
          dictionaryTitle: "待我审",
          dictionaryValue: "1",
        },
        {
          dictionaryTitle: "已审批",
          dictionaryValue: "2",
        },
        {
          dictionaryTitle: "已撤销",
          dictionaryValue: "3",
        },
        {
          dictionaryTitle: "已通过",
          dictionaryValue: "4",
        },
        {
          dictionaryTitle: "已拒绝",
          dictionaryValue: "5",
        },
      ],
      number: 1,
      approvalOpinion: "",
      acceptedShow: false,
      rowData: "",
      //当前登录人
      staffId: ""
    };
  },
  beforeRouteEnter(to, from, next) {
    //清除本地localStorage缓存
    if (from.name == null) {
      localStorage.removeItem("currentLabel");
    }
    next();
  },
  created() {
    this.getStaffFuntionModelList();
  },
  mounted() {
    this.staffId = getStaffId();
    this.checkPhoneorMobel();
    // this.initDicData()
    this.getStaffFuntionModelList();
    // this.finished = false;
    // this.loading = true;
    // this.refreshing = false
    // this.orders=[]
    // this.onLoad();
    this.finished = false;
    this.loading = true;
    this.refreshing = false;
  },
  activated() {
    // this.getStaffFuntionModelList();
    // let currentLabel = JSON.parse(localStorage.getItem("currentLabel")); //先读取local里存储的历史记录
    // if (currentLabel != null) {
    //   if (currentLabel != "") {
    //     this.number = Number(currentLabel);
    //   }
    // }
    this.number = 1;
    this.page.currentPage = 1;
    this.orders = [];
    this.onLoad();
  },
  methods: {
    revokeSave() {
      Dialog.confirm({
        title: "友情提示",
        message: "确定撤回吗？",
      }).then(() => {
        let that = this;
        console.log(that.rowData);
        let initData = {};
        initData.staff_id = getStaffId();
        initData.id = that.rowData.id;
        initData.approval_id = that.rowData.approval_id;
        initData.approvalOpinion = that.approvalOpinion;
        resumeApprovalRevoke(initData).then((res) => {
          console.log(res.data);
          if (res.data.code == "0") {
            responseUtil.alertOK(that)
          } else {
            responseUtil.alertMsg(that, res.data.msg);
          }
            that.finished = false;
            that.orders = []
            that.page =  {currentPage:1,numberPage:10}
            that.loading = true;
            that.refreshing = false
            that.noContent = false
            that.acceptedShow =false
            that.onLoad();
        //   that.acceptedShow =false
        //   that.onLoad();
        });
      });
    },
    saveDepartApply(type, val) {
      if (type == "approval") {
        this.$router.push({
          name: "jobApproval",
          query: {
            ...val,
          },
        });
      }
    },
    //下拉刷新
    onRefresh() {
      // 清空列表数据
      this.finished = false;
      this.orders = [];
      this.page = { currentPage: 1, numberPage: 3 };
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.refreshing = false;
      this.noContent = false;
      this.onLoad();
      responseUtil.alertMsg(this, "刷新成功");
    },
    search() {
      if (this.searchValue != "") {
        localStorage.setItem("searchValue", JSON.stringify(this.searchValue));
      } else {
        localStorage.removeItem("searchValue");
      }
      this.orders = [];
      this.page.currentPage = 1;
      this.finished = false;
      this.loading = true;
      this.noContent = false;
      this.onLoad();
    },
    onConfirm(value, index) {
      if (value) {
        localStorage.setItem("currentLabel", JSON.stringify(index));
        this.number = index;
      }
      this.orders = [];
      this.page.currentPage = 1;
      this.finished = false;
      this.showPicker = false;
      this.loading = true;
      this.noContent = false;
      this.onLoad();
    },
    onLoad() {
      let that = this;
      let queryData = {
        currentPage: that.page.currentPage,
        numberPage: that.page.numberPage,
        staff_id: getStaffId(),
        myApproval: this.number,
        searchValue: this.searchValue,
      };

      browseInterpolateApprovalList(queryData).then(function (response) {
        responseUtil.dealResponse(that, response, () => {
          that.count = response.data.data.pageCount;
          let list = response.data.data.data ? response.data.data.data : [];
          for (let i = 0; i < list.length; i++) {
            that.orders.push(list[i]);
          }
          console.log("that.orders");
          console.log(that.orders);
          if (that.refreshing) {
            that.refreshing = false;
          }
          // 加载状态结束
          that.loading = false;
          that.page.currentPage++;
          // 数据全部加载完成
          if (that.orders.length >= that.count) {
            that.finished = true;
          }
          // 判断数据是否为空，空则显示空状态图
          if (that.orders.length == 0) {
            that.isEmptuyFlag = true;
          } else {
            that.isEmptuyFlag = false;
          }

          if (1 == that.page.currentPage && (!list || 0 == list.length)) {
            that.noContent = true;
          } else {
            that.noContent = false;
          }
        });
      });
    },

    detail(value) {
      this.$router.push({
        name: "jobApprovalDetail",
        query: {
          ...value,
        },
      });
    },
    revoke(item) {
      this.approvalOpinion = "";
      this.rowData = item;
      this.acceptedShow = true;
    },

    checkPhoneorMobel() {
      if (checkAndroid()) {
        this.androidFlg = true;
      } else if (checkIOS()) {
        this.androidFlg = false;
      }
    },
    getStaffFuntionModelList() {
      var that = this;
      let data = {};
      data.staff_id = getStaffId();
      data.menuName = "newbieProbationApproval_index";
      getStaffFuntionModelList(data).then(function (response) {
        responseUtil.dealResponse(that, response, () => {
          that.authButtonsList = response.data.data.data;
          console.log(that.authButtonsList, "that.authButtonsList=====");
        });
      });
    },
    checkAuthList,
    //初始化字典数据
    initDicData: function () {
      const that = this;
      let initData = {};
      initData.fdName = ["RESUMESTATUSMAP"];
      queryBaseData(initData).then(function (response) {
        responseUtil.dealResponse(that, response, () => {
          let list = response.data.data.RESUMESTATUSMAP;
          for (let i = 0; i < list.length; i++) {
            that.columns.push(list[i]);
          }
          console.log(that.columns);
        });
      });
    },
  },
};
</script>

<style scoped lang="less">
* {
  padding: 0;
  margin: 0;
}

//搜索+选择
.head {
  width: 100%;
  position: fixed;
  z-index: 8;
  overflow: hidden;
  background: #f8f8f8;
  //搜索框
  .searchDiv {
    width: 100%;
    height: 40px;

    .search {
      width: 90%;
      height: 40px;
      margin: 10px auto;
      border-radius: 20px;
      background-color: #eeeeee;
      display: flex;

      input {
        width: 80%;
        background-color: #eeeeee;
        border: none;
        font-size: 15px;
      }
    }

    .search:before {
      content: "";
      display: block;
      background: url("../../../assets/images/search.png") no-repeat center
        center;
      background-size: 50% 50%;
      width: 40px;
      height: 40px;
    }

    input::placeholder {
      font-size: 15px;
    }
  }
  //下拉框
  .downMenu {
    height: 28px;
    width: 20%;
    margin: 15px 15px 0;
    line-height: 40px;
    display: flex;
    align-items: center;
    .downMenuValue {
      /*width: 14%;*/
      font-size: 14px;
      color: #ff5d3b;
    }

    .arrows {
      height: 0;
      width: 0;
      border: 3px solid;
      border-color: black transparent transparent transparent;
      margin-top: 1px;
      margin-left: 8px;
    }

    .uparrows {
      height: 0;
      width: 0;
      border: 3px solid;
      border-color: transparent transparent black transparent;
      margin-bottom: 6px;
      margin-left: 8px;
    }
  }
}

.blank-weight {
  height: 93px;
}
//列表
.reviews {
  overflow: hidden;
  position: relative;
}
.review {
  margin: 0 15px 15px 15px;
  height: auto;
  background-color: white;
  border-radius: 8px;
  overflow: hidden;
}

.top-title {
  margin: 0;
  width: fit-content;
  padding: 0 5px;
  height: 20px;
  font-size: 10px;
  text-align: center;
  line-height: 22px;
  border-radius: 8px 0;
  color: white;
}

.top-title-1{
  background: linear-gradient(to right, #ffbe72, #ff6c41);
}
.top-title-2{
  background: linear-gradient(to right, #63cb96, #30d0ac);
}
.top-title-3{
  background: linear-gradient(to right, #cc004b, #660026);
}
.top-title-4{
  background: linear-gradient(to right, #bfcbd9, #a8b9cc);
}

/*入职表*/
.vacationForm {
  /*height: 168px;*/
  width: 315px;
  margin-left: 15px;
  margin-bottom: 15px;
  /*line-height: 30px;*/
}

.vacationForm div {
  line-height: 20px;
}

/*标题*/
.vacationType {
  color: #ff5809;
  font-size: 14px;
  font-weight: bold;
  margin-top: 8px;
}

/*时间*/
.start_endTime {
  color: #ff5809;
  font-size: 14px;
  font-weight: bold;
  margin-top: 6px;
}
/*行信息标题label*/
.text {
  font-weight: bold;
  font-size: 14px;
  margin-right: 0.2rem;
  /*letter-spacing: 1px;*/
}
/*行信息内容*/
.content {
  font-size: 13px;
  color: #9a9a9a;
}
.line {
  width: 100%;
  border-bottom: 1px solid #d8d8d8;
}
/*    下部按钮*/
.bottom {
  display: flex;
  justify-content: right;
  align-items: center;
  margin: 0.3rem 0;
}

.check,
.look {
  /*float: right;*/
  //margin-top: 0.2rem;
  margin-right: 0.5rem;
  height: 0.82rem;
  width: 2.3rem;
  border-radius: 0.2rem;
  border: 0rem solid transparent;
  box-shadow: none;
  line-height: 0.7rem;
  font-size: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
}
/*按钮*/
.check {
  background: -webkit-linear-gradient(left, #ffc274, #ff5d3b);
  color: white;
}
.look {
  padding: 0.01rem;
  border: 0.03rem solid transparent;
  background-image: linear-gradient(white, white),
    linear-gradient(to right, #ffc274, #ff5d3b);
  background-clip: padding-box, border-box;
  background-origin: border-box;
  color: #ff5d3b;
}
.sub_btn {
  //margin: 0.8rem auto 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-bottom: 0.6rem;
  padding-top: 0.7rem;;

  //position: absolute;
  //bottom: 1rem;
  //width: 94%;
}
.saveButton_Enable{
  background: linear-gradient(to right, #fdc07f 0px, #f86513 100%) repeat scroll 0% 0%;
  color: white;
  width: 345px;
  height: 50px;
  border-radius: 8px;
  font-size: 18px;
  line-height: 1.22rem;
  text-align: center;
  //margin: 0.1rem 0 0.3rem;

}
.saveButton_Disable{
  display: block;
  background-color: rgba(184, 184, 184, 0.2);
  color: white;
  width: 345px;
  height: 50px;
  border-radius: 8px;
  font-size: 18px;
  line-height: 1.22rem;
  text-align: center;
  margin: 0.1rem;
}
.part-inputpart-row {
  display: flex;
  flex-direction: row;
  border-radius: 8px;
  align-items: center;
  height: 45px;
  // background-color: #ffffff;
  margin-top: 15px;
}
.otherExpenses-row-enablenon {
  margin-left: 10px;
}
.part-inputpart-row-header {
  font-weight: 900;
  font-size: 15px;
  white-space: nowrap;
}
.smallgraytext {
  color: #d8d8d8;
  font-size: 12px;
  margin-bottom: -3px;
}
.cancelOrderPopupTextValue {
  margin-bottom: 20px;
}
.part-button {
  height: 45px;
  border-radius: 10px;
  // background-color: #ededed;
  color: #ffffff;
  text-align: center;
  line-height: 45px;
  font-size: 15px;
  font-weight: 900;
  margin-top: 20px;
}
.part-button-enabled {
  background-image: linear-gradient(to right, #ffc274, #ff5d3b);
}
.van-popup--bottom {
  /*z-index: 2004;*/
  background-color: #f8f8f8;
}
.mes_body {
  border-radius: 10px;
  background-color: #ffff;
}
.popMaincontant {
  width: 92%;
  margin: 0 auto;
}
</style>